
export default function text() {

  var body = [
    //PORT
    `<p><b>A Hub e sua Ouvidoria:</b></p>
    
    <p>A Hub Fintech está presente a 10 anos no mercado. Atuando em soluções modulares onde somos capazes de integrar diretamente a cadeia 
    de negócios dos nossos clientes.</p>

    <p>A valorização do relacionamento com o cliente sempre fez parte dos princípios dessa Instituição.</p>

    <p>Somos a voz do cliente dentro da organização e vemos a reclamação como matéria prima para a identificação de oportunidades de 
    melhorias e para desenvolver novas ações em parceria com as demais áreas da empresa.</p>

    <p>O cliente sempre foi e continua sendo foco de nossas ações e sempre é tema discursões  entre diversos setores da empresa e já faz 
    parte da nossa cultura organizacional.</p>

    <p>É com esse pensamento que apresentamos neste relatório nossos principais números e informações referentes ao primeiro semestre de 2021.</p>
    
    <p><b>O que é ouvidoria?</b></p>
  
    <p>Ouvidoria é um espaço que funciona como uma ponte entre o usuário e a Hub. É um serviço aberto aos clientes para escutar as 
    reclamações, as denúncias, as sugestões e também os elogios referentes aos diversos serviços disponíveis para o usuário.</p>

    <p><b>Como funciona a ouvidoria?</b></p>

    <p>Os canais de acesso à ouvidoria estão abertos através de e-mail e telefone, onde o cliente expõe sua dúvidas, com todos os detalhes, 
    que permitam ao ouvidor encaminhar aos setores competentes e junto com eles, seguir passo a passo o andamento do serviço e retornar 
    ao solicitante a resolução do problema.</p>

    <p>O ouvidor é a pessoa que atua como centralizador e facilitador das relações entre o usuário e a Hub, recolhendo as informações 
    precisas e acompanhando o andamento do serviço solicitado, contribuindo para a melhoria da gestão e consequentemente para a 
    satisfação do cliente.</p>
    
    <p><b>Quando deve ligar na Ouvidoria?</b></p>
    
    <p>A Ouvidoria, ou central de atendimento de uma empresa deve ser acionada quando o cliente não se sentir atendido de forma eficiente, 
    ou seja, depois que tiver entrado em contato com o setor que deveria ter solucionado o problema e ele não tenha sido resolvido de 
    forma conveniente.</p>
    
    <p>Através da ouvidoria, você, como cliente, pode fazer uma reclamação ou uma denúncia. Qualquer motivo que você tenha, como 
    insatisfação, protesto com relação a um serviço prestado ou omissão por parte de qualquer setor ou de qualquer pessoa que lhe 
    tenha atendido, pode ser repassado para a ouvidoria, que tem como obrigação lhe oferecer uma solução satisfatória.</p>
    
    <p>A ouvidoria tem um papel diferente dos outros canais de comunicação, uma vez que deve receber, avaliar e encaminhar as demandas, 
    observando sempre o interesse do cliente. Cabe à ouvidoria resolver todos os problemas no menor prazo possível e de maneira 
    totalmente objetiva.</p>
    
    <p>Antes de contatar a Ouvidoria da HubFintech, lembramos que nossa Central de Atendimento pelo telefone 0800 880 8888 está 
    capacitada para esclarecer qualquer tipo de dúvida ou questionamento.</p>

    <p>Quando acionar a Ouvidoria, poderá ser pedido o protocolo gerado em Nossa Central de Atendimento (caso tenha sido feito um 
    primeiro contato pela Central de Atendimento), bem como poderá ser solicitado mais informações sobre sua dúvida ou 
    questionamento.</p>

    <p>
        <b>E-mail da Ouvidoria:</b><br/>
        ouvidoria@fintechmagalu.com.br
    </p>
    <p>
        <b>Telefone da Ouvidoria:</b><br/>
        0800 942 6167
    </p>
    <p>
        <b>Telefone da Ouvidoria para Deficientes Auditivos ou de Fala:</b><br/>
        0800 773 9355
    </p>

    
    <p><b>Reclamação</b></p>
    <p>
        <a
        href="https://ouvidoriahub.zendesk.com/hc/pt-br/requests/new?"
        target="_blank"
        rel="noreferrer"
        >
            Cadastrar sua reclamação
        </a>
    </p>
    
    <p><b>Relatórios semestrais Ouvidoria:</b></p>`,

    //INGLÊS

    `<p><b>Hub and its Ombudsman:</p></b>

    <p>Hub Fintech has been present in the market for 10 years. Operating in modular solutions where we are able to directly integrate 
    the business chain of our clients.</p>
    
    <p>The appreciation of the relationship with the client has always been part of the principles of this institution.</p>
    
    <p>We are the voice of the customer within the organization, and we see the complaint as raw material for the identification of 
    opportunities for improvement and to develop new actions in partnership with the other areas of the company.</p>
    
    <p>The customer has always been and continues to be the focus of our actions and is always the subject of discussions between various 
    sectors of the company and is already part of our organizational culture.</p>
    
    <p>It is with this thought in mind that we present in this report our main numbers and information regarding the 
    first half of 2021.</p>
        
    <p><b>What is an ombudsman?</b></p>
    
    <p>Ombudsman is a space that works as a bridge between the user and Hub. It is a service open to customers to listen to complaints, 
    accusations, suggestions and also compliments regarding the various services available to the user.</p>
    
    <p><b>How does the ombudsman work?</b></p>
    
    <p>The access channels to the ombudsman are open through e-mail and telephone, where the customer exposes his or her doubts, with all 
    the details that allow the ombudsman to forward them to the competent sectors and, together with them, follow the progress of the 
    service step by step and return to the applicant the resolution of the problem.</p>
    
    <p>The ombudsman is the person who acts as a centralizer and facilitator of relations between the user and Hub, collecting precise 
    information and following the progress of the service requested, contributing to the improvement of management and consequently to
    customer satisfaction.</p>
    
    <p><b>When should you call the Ombudsman?</b></p>
    
    <p>The Ombudsman's Office, or a company's call center, should be called when the client does not feel he has been served efficiently, 
    that is, after he has contacted the sector that should have solved the problem and it has not been solved conveniently.</p>
    
    <p>Through the ombudsman, you, as a customer, can make a complaint or a denunciation. Any reason you may have, such as dissatisfaction, 
    protest about a service provided, or omission on the part of any sector or of any person who has assisted you, can be passed on to 
    the ombudsman, who is obliged to offer you a satisfactory solution.</p>
    
    <p>The ombudsman has a different role from the other communication channels, since it must receive, evaluate and forward the demands, 
    always observing the client's interest. It is up to the ombudsman to solve all the problems in the shortest possible time and in a 
    totally objective way.</p>
    
    <p>Before contacting the HubFintech Ombudsman, we remind you that our Call Center on 0800 880 8888 is qualified to clarify any type of 
    doubt or question.</p>
    
    <p>When you call the Ombudsman, you can request the protocol generated in our Call Center (in case a first contact was made through 
    the Call Center), as well as more information about your doubt or question.</p>
    
    <p>
        <b>Ombudsman's email</b><br/>
        ouvidoria@fintechmagalu.com.br
    </p>

    <p>
        <b>Ombudsman's phone number</b><br/>
        0800 942 6167
    </p>
    <p>
        <b>Ombudsman's phone number for the Hearing or Speech Impaired:</b><br/>
        0800 773 9355
    </p>

    <p><b>Claim</b></p>
    <p>
        <a
        href="https://ouvidoriahub.zendesk.com/hc/pt-br/requests/new?"
        target="_blank"
        rel="noreferrer"
        >
            Register your complaint
        </a>
    </p>
    
    <p><b>Ombudsman half-yearly reports:</p></b>`,

    //ESPANHOL

    `<p><b>Hub y su Defensor del Pueblo:</b></p>

    <p>Hub Fintech está presente en el mercado desde hace 10 años. Operando en soluciones modulares donde somos capaces de integrar 
    directamente la cadena de negocio de nuestros clientes.</p>
    
    <p>La valoración de la relación con el cliente siempre ha formado parte de los principios de esta institución.</p>
    
    <p>Somos la voz del cliente dentro de la organización y vemos la queja como materia prima para la identificación de oportunidades de 
    mejora y para desarrollar nuevas acciones en colaboración con las demás áreas de la empresa.</p>
    
    <p>El cliente siempre ha sido y sigue siendo el centro de nuestras acciones y siempre es objeto de debate entre los distintos sectores 
    de la empresa y ya forma parte de nuestra cultura organizativa.</p>
    
    <p>Con este pensamiento presentamos en este informe nuestras principales cifras e información relativa al primer semestre de 2021.</p>
        
    <p><b>¿Qué es el defensor del pueblo?</b></p>
    
    <p>El Ombudsman es un espacio que funciona como puente entre el usuario y el Hub. Se trata de un servicio abierto a los clientes para 
    escuchar quejas, denuncias, sugerencias y también felicitaciones en relación con los distintos servicios a disposición del usuario.</p>
    
    <p><b>¿Cómo funciona el Defensor del Pueblo?</b></p>
    
    <p>Los canales de acceso al ombudsman están abiertos a través del correo electrónico y el teléfono, donde el cliente expone sus dudas, 
    con todos los detalles, que permiten al ombudsman remitir a los sectores competentes y junto con ellos, seguir paso a paso el 
    progreso del servicio y devolver al solicitante la resolución del problema.</p>
    
    <p>El ombudsman es la persona que actúa como centralizador y facilitador de las relaciones entre el usuario y el Hub, recogiendo la 
    información precisa y haciendo un seguimiento de la marcha del servicio solicitado, contribuyendo a la mejora de la gestión y, en 
    consecuencia, a la satisfacción del cliente.</p>
    
    <p><b>¿Cuándo debe llamar a la Oficina del Defensor del Pueblo?</b></p>
    
    <p>La Oficina del Defensor del Pueblo, o el centro de atención telefónica de una empresa, debe activarse cuando el cliente no se 
    sienta atendido de forma eficiente, es decir, después de que haya contactado con el sector que debería haber resuelto el problema y 
    éste no se haya solucionado convenientemente.</p>
    
    <p>A través del Defensor del Pueblo, usted, como cliente, puede presentar una queja o una denuncia. Cualquier motivo que tenga, como 
    una insatisfacción, una protesta respecto a un servicio prestado o una omisión por parte de cualquier sector o de cualquier persona 
    que le haya atendido, puede ser trasladado al defensor del pueblo, que está obligado a ofrecerle una solución satisfactoria.</p>
    
    <p>El defensor del pueblo tiene un papel diferente al de otros canales de comunicación, ya que debe recibir, evaluar y transmitir las 
    demandas, siempre observando el interés del cliente. Corresponde al Defensor del Pueblo resolver todos los problemas en el menor 
    tiempo posible y de forma totalmente objetiva.</p>
    
    <p>Antes de contactar con el Ombudsman de HubFintech, le recordamos que nuestro Call Center en el 0800 880 8888 está capacitado para 
    aclarar cualquier tipo de duda o pregunta.</p>
    
    <p>Cuando llame al Defensor del Pueblo, podrá solicitar el protocolo generado en nuestro Centro de llamadas (si ha realizado un 
    primer contacto a través del Centro de llamadas), así como más información sobre su duda o pregunta.</p>
    
    <p>
        <b>Correo electrónico de la Oficina del Defensor del Pueblo:</b><br/>
        ouvidoria@fintechmagalu.com.br
    </p>
    
    <p>
        <b>Número de teléfono del Defensor del Pueblo:</b><br/>
        0800 942 6167
    </p>
    <p>
        <b>Número de teléfono del Defensor del Pueblo para Personas con Problemas de Audición o del Habla:</b><br/>
        0800 773 9355
    </p>
    
    <p><b>Reclamación</p></b>
    
    <p>
        <a
        href="https://ouvidoriahub.zendesk.com/hc/pt-br/requests/new?"
        target="_blank"
        rel="noreferrer"
        >
            Registre su queja
        </a>
    </p>
    
    <p><b>Informes semestrales del Defensor del Pueblo:</p></b>`
  ];

  return body;
}
