import React from 'react';
import Chat from '../components/Chat';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import HeaderOuvidoria from '../components/Ouvidoria';
import BodyOuvidoria from '../components/Ouvidoria/Body';
import SEO from '../components/seo';

export default function Ouvidoria() {
  return (
    <Layout>
      <SEO title="Ouvidoria" />
      <HeaderOuvidoria />
      <BodyOuvidoria />
      <Footer />
      <Chat />
    </Layout>
  );
}
