import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  text-justify: inter-word;
  align-items: center;
  flex: 1;
  flex-direction: column;
  padding: 25px 2rem;

  div {
    width: 60%;
    text-align: left;
    p {
      font-size: 1.6rem;
      line-height: 1.69;
      text-align: left;
      b {
        color: #00b1b1;
      }
    }
  }
`;
