import { Link } from 'gatsby';
import Parser from 'html-react-parser';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, EN, ES, Header, PT } from './styles';

export default function HeaderOuvidoria() {
  const { idioma } = useContext(LanguageContext);
  const ombudsman = ['Ouvidoria <hr />', 'Ombudsman <hr />', 'Defensoría <hr />'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          <b>{Parser(ombudsman[idioma])}</b>
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}
