import 'bootstrap/dist/css/bootstrap.css';
import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import Ouvidoria20221 from '../../../images/pdf/ouvidoria/Ouvidoria_Relatório_Semestral_1Semestre2022.pdf';
import Ouvidoria20222 from '../../../images/pdf/ouvidoria/Ouvidoria_Relatório_Semestral_2Semestre2022.pdf';
import Ouvidoria20231 from '../../../images/pdf/ouvidoria/Ouvidoria_Relatório_Semestral_1Semestre2023.pdf';
import Ouvidoria20232 from '../../../images/pdf/ouvidoria/Ouvidoria_Relatório_Semestral_2Semestre2023.pdf';
import { Container } from './styles';
import text from './text/text';

export default function BodyOuvidoria() {
  const { idioma } = useContext(LanguageContext);

  var body = text();
  var report20221 = [
    `Relatório ouvidoria primeiro semestre 2022`,
    `Ombudsman report first semester 2022`,
    `Informe del Defensor del Pueblo en el primer semestre de 2022`,
  ];

  var report20222 = [
    `Relatório ouvidoria segundo semestre 2022`,
    `Ombudsman report second semester 2022`,
    `Informe del Defensor del Pueblo en el segundo semestre de 2022`,
  ];

  var report20231 = [
    `Relatório ouvidoria primeiro semestre 2023`,
    `Ombudsman report first semester 2023`,
    `Informe del Defensor del Pueblo en el primer semestre de 2023`,
  ];

  var report20232 = [
    `Relatório ouvidoria segundo semestre 2023`,
    `Ombudsman report second semester 2023`,
    `Informe del Defensor del Pueblo en el segundo semestre de 2023`,
  ];

  var ouvidoria20221 = [Ouvidoria20221, Ouvidoria20221, Ouvidoria20221];
  
  var ouvidoria20222 = [Ouvidoria20222, Ouvidoria20222, Ouvidoria20222]
  
  var ouvidoria20231 = [Ouvidoria20231, Ouvidoria20231, Ouvidoria20231]

  var ouvidoria20232 = [Ouvidoria20232, Ouvidoria20232, Ouvidoria20232]

  return (
    <Container>
      <div>
        {Parser(body[idioma])}
        <a href={ouvidoria20221[idioma]} download>
          <p>
            <a href={ouvidoria20221[idioma]} download>
              {report20221[idioma]}
            </a>
          </p>
        </a>
        <a href={ouvidoria20222[idioma]} download>
          <p>
            <a href={ouvidoria20222[idioma]} download>
              {report20222[idioma]}
            </a>
          </p>
        </a>
        <a href={ouvidoria20231[idioma]} download>
          <p>
            <a href={ouvidoria20231[idioma]} download>
              {report20231[idioma]}
            </a>
          </p>
        </a>
        <a href={ouvidoria20232[idioma]} download>
          <p>
            <a href={ouvidoria20232[idioma]} download>
              {report20232[idioma]}
            </a>
          </p>
        </a>
      </div>
    </Container>
  );
}
